import {
  createPlugin,
  // createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const xgeeksGitlabCommitsPlugin = createPlugin({
  id: 'xgeeks-gitlab-commits-plugin',
  routes: {
    root: rootRouteRef,
  },
});

export const XgeeksGitlabCommitsContent = xgeeksGitlabCommitsPlugin.provide(
  createComponentExtension({
    name: 'xgeeksGitlabCommitsContent',
    component: {
      lazy: () =>
        import('./components/GitlabCommitsComponent').then(
          m => m.GitlabCommitsComponent,
        ),
    },
  }),
);
