import {
  createPlugin,
  // createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const xgeeksGithubCommitsPluginPlugin = createPlugin({
  id: 'xgeeks-github-commits-plugin',
  routes: {
    root: rootRouteRef,
  },
});

export const XgeeksGithubCommitsContent =
  xgeeksGithubCommitsPluginPlugin.provide(
    // createRoutableExtension({
    //   name: 'XgeeksGithubCommitsPluginPage',
    //   component: () =>
    //     import('./components/ExampleComponent').then(m => m.ExampleComponent),
    //   mountPoint: rootRouteRef,
    // }),
    createComponentExtension({
      name: 'xgeeksGithubCommitsContent',
      component: {
        lazy: () =>
          import('./components/GithubCommitsComponent').then(
            m => m.GithubCommitsComponent,
          ),
      },
    }),
  );
