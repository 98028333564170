import React from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
// Roadie's Github PullRequest Plugin
import {
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';

// Roadie's Github Insights Plugin
import {
  isGithubInsightsAvailable,
  EntityGithubInsightsReleasesCard,
  // EntityGithubInsightsContent,
  // EntityGithubInsightsLanguagesCard,
  // EntityGithubInsightsReadmeCard,
} from '@roadiehq/backstage-plugin-github-insights';

// gitlab
// import {
//   isGitlabAvailable,
//   EntityGitlabPipelinesTable,
//   EntityGitlabMergeRequestsTable,
//   EntityGitlabContent,
//   EntityGitlabLanguageCard,
//   EntityGitlabContributorsCard,
//   EntityGitlabMergeRequestStatsCard,
//   EntityGitlabPipelinesTable,
// } from '@loblaw/backstage-plugin-gitlab';

import {
  isGitlabAvailable,
  EntityGitlabPipelinesTable,
  EntityGitlabMergeRequestsTable,
  EntityGitlabReleasesCard,
} from '@immobiliarelabs/backstage-plugin-gitlab';

import {
  // EntitySentryCard, // single card element
  EntitySentryContent, // full page
} from '@backstage/plugin-sentry';

// veecode-gh-workflows-plugin
import {
  GithubWorkflowsList,
  isGithubAvailable,
  // isGithubWorkflowsAvailable,
  GithubWorkflowsCard,
} from '@veecode-platform/backstage-plugin-github-workflows';

import { XgeeksGithubCommitsContent } from '../../plugins/xgeeks-github-commits-plugin/src';
import { XgeeksGitlabCommitsContent } from '../../plugins/xgeeks-gitlab-commits-plugin/src';
// import { InfoCard } from '@backstage/core-components';
// import { styles as useStyles } from './roadieReleaseClone/stylesRoadie';

// Build an Empty card, to avoid a black space in UI
// const NoReleasesCard = () => {
//   const classes = useStyles();

//   return (
//     <InfoCard title="Releases" className={classes.infoCard}>
//       No Releases
//     </InfoCard>
//   );
// };

// TODO: function isEmptyFragment(frag: JSX.Element) {
// const isNotEmptyFragment = (frag: JSX.Element) => {
//   console.log(frag.children);
//   /* {isEmpty = Boolean(React.isValidElement(<></>) && React.isValidElement(<EntityGithubInsightsReleasesCard/>);)} */
//   // const container = render(frag);
//   // console.log(container);
//   // return container === '';
//   // if (variableToInspect.type) {
//   //   return variableToInspect.type === React.Fragment;
//   // }
//   // return variableToInspect === React.Fragment;
//   return true;
// };

const commitsContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubAvailable}>
      <XgeeksGithubCommitsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <XgeeksGitlabCommitsContent />
    </EntitySwitch.Case>
  </EntitySwitch>
);
// build result for Releases
const releasesContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubInsightsAvailable}>
      <EntityGithubInsightsReleasesCard />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabReleasesCard showSortSelector={false} />
    </EntitySwitch.Case>
    {/* <EntitySwitch.Case if={isNotEmptyFragment}>
      <EntityGithubInsightsReleasesCard />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <NoReleasesCard />
    </EntitySwitch.Case> */}
  </EntitySwitch>
);
const WorkflowsContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <GithubWorkflowsCard />
      <GithubWorkflowsList />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more
          about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabPipelinesTable />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const prContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
      <EntityGithubPullRequestsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabMergeRequestsTable />
    </EntitySwitch.Case>
  </EntitySwitch>
  // <>
  //   <EntityLayout.Route
  //     if={isGithubPullRequestsAvailable}
  //     path="/pull-requests"
  //     title="Pull Requests"
  //   >
  //
  //   </EntityLayout.Route>

  //   <EntityLayout.Route
  //     if={isGitlabAvailable}
  //     path="/pull-requests"
  //     title="Pull Requests"
  //   >
  //     <>
  //       <EntityGitlabMergeRequestStatsCard />
  //       <EntityGitlabMergeRequestsTable />
  //     </>
  //   </EntityLayout.Route>
  // </>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    <Grid item md={3} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    <Grid item md={3} xs={12}>
      {releasesContent}
    </Grid>
    {/* Not good here because, when entering the pages asks imediattly for github auth */}
    {/* <Grid item md={6}>
      <EntityGithubPullRequestsOverviewCard />
    </Grid> */}

    {/* --- SENTRY single component--- */}
    {/* <Grid item xs={12} sm={6} md={4}>
      <EntitySentryCard />
    </Grid> */}
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      {prContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/commits" title="Commits">
      {commitsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route if={isGitlabAvailable} path="/gitlab" title="Gitlab">
      <EntityGitlabContent />
    </EntityLayout.Route> */}

    {/* <EntitySwitch.Case>
      <EntityGithubActionsContent />
    </EntitySwitch.Case> */}

    {/* <EntityLayout.Route
      path="/sentry"
      title="Sentry"
      if={entity =>
        Boolean(entity?.metadata?.annotations?.['sentry.io/project-slug'])
      }
    >
      <EntitySentryContent />
    </EntityLayout.Route> */}

    {/* --- REMOVED BECAUSE ONLY FE WEBSITE USES SENTRY - BE USES DATADOG --- */}
    {/* <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route> */}

    {/* workflows plugin */}
    <EntityLayout.Route
      if={isGithubAvailable}
      path="/workflows"
      title="Workflows"
    >
      {WorkflowsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      {prContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/commits" title="Commits">
      {commitsContent}
    </EntityLayout.Route>

    {/* workflows plugin */}
    <EntityLayout.Route
      if={isGithubAvailable}
      path="/workflows"
      title="Workflows"
    >
      {WorkflowsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
